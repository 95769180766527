import React from "react";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";

const Owp = () => {
  const lang = "de";

  return (
    <Layout
      seoTitle="Allgemeine Transportbedingungen"
      lang={lang}
      translationPL="/owp/"
      translationEN="/en/gtoc/"
    >
      <SmallBanner title="Allgemeine Transportbedingungen" lang={lang} />

      <section className="default-main">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <p>
                Die vorliegenden Allgemeinen Transportbedingungen, nachstehend
                ATB genannt, gelten für Frachtverträge, die zwischen den
                Unternehmen Palettenwerk Kozik Sp. z o.o. oder Palko Sp. z
                o.o., nachstehend Auftraggeber genannt, und dem Frachtführer
                aufgrund von Transportaufträgen, die der Auftraggeber dem
                Frachtführer erteilt, abgeschlossen werden.
              </p>
              <p>1. Allgemeine Bestimmungen</p>
              <ul>
                <li>
                  Diese ATB bilden den integralen Bestandteil jeden vom
                  Auftraggeber erteilten Transportauftrags. Abweichungen von der
                  Anwendung dieser ATB bedürfen einer Schriftform unter
                  Androhung der Nichtigkeit.
                </li>
                <li>
                  Die Annahme des Transportauftrags zur Ausführung hat der
                  Frachtführer per E-Mail an die Adresse 
                  <a href="mailto:logistyka@palettenwerk.pl">
                    logistyka@palettenwerk.pl
                  </a>{" "}
                  zu bestätigen. Wird die Verweigerung der Annahme des
                  Transportauftrags nicht innerhalb von 30 Minuten nach dessen
                  Eingang an die im vorstehenden Satz genannte Adresse gesendet,
                  so gilt dies als Annahme des Transportauftrags zur Ausführung
                  durch den Frachtführer.
                </li>
                <li>
                  Die Annahme des Transportauftrags zur Ausführung durch den
                  Frachtführer ist mit dem Abschluss eines Transportvertrags zu
                  den im Transportauftrag und in diesen ATB festgelegten
                  Bedingungen gleich.
                </li>
              </ul>
              <p>2. Erklärungen und Zusicherungen des Frachtführers</p>
              <ul>
                <li>
                  Mit der Annahme des Transportauftrags zur Ausführung erklärt
                  der Frachtführer, dass er: a/ alle Bedingungen und formale
                  Anforderungen für die Erbringung von Transportdienstleistungen
                  im inländischen und/oder ausländischen Verkehr erfüllt und die
                  Transporttätigkeit gewerbsmäßig ausübt; b/ über eine gültige
                  und aktuelle Haftpflichtversicherung für Schäden an den
                  transportierten Gütern mit einer Garantiesumme in Höhe von
                  mindestens 200 % des Wertes der zum Transport übernommenen
                  Güter verfügt; c/ die Fahrzeuge, die er zur Beladung
                  bereitstellt und mit denen er die Transportaufträge ausführt,
                  alle erforderlichen technischen Voraussetzungen erfüllen und
                  mit allen erforderlichen Geräten gemäß dem geltenden Recht
                  ausgestattet sind; die eingesetzten Fahrzeuge den
                  erforderlichen Standards entsprechen, technisch leistungsfähig
                  sind, hinsichtlich der Nutzlast und der im Transportauftrag
                  festgelegten Anforderungen an Höhe und Länge geeignet sind;
                  sauber und frei von Fremdgerüchen sind; der Laderaum frei von
                  anderen Gütern ist und angemessen gegen die eventuelle
                  Beschädigung der Sendung und deren Exposition gegenüber
                  nachteiligen Umwelteinflüssen während des Transports geschützt
                  ist. d/ unter Mega+-Fahrzeug ein Fahrzeug mit einer Höhe von
                  mindestens 298 cm und einer Länge von 13,6 m versteht; unter
                  Mega-Fahrzeug ein Fahrzeug mit einer Höhe von mindestens 294
                  cm und einer Länge von 13,6 m versteht; unter
                  Standard-Fahrzeug ein Fahrzeug mit einer Höhe von mindestens
                  273 cm und einer Länge von 13,6 m versteht; unter Set-Fahrzeug
                  ein Fahrzeug mit einer Höhe von mindestens 298 cm und einer
                  Länge von 7,7 m versteht.
                </li>
                <li>
                  Der Frachtführer ist verpflichtet, auf jedes Verlangen des
                  Auftraggebers unverzüglich die Versicherungspolice, die den
                  Abschluss des Haftpflichtversicherungsvertrags bestätigt,
                  sowie Bescheinigungen, Lizenzen und andere mit der Fracht
                  zusammenhängende Dokumente vorzulegen. Der Auftraggeber ist
                  berechtigt, von den vorgelegten Dokumenten eine Kopie
                  anzufertigen.
                </li>
              </ul>
              <p>3. Grundsätze zur Transportausführung</p>
              <ul>
                <li>
                  Der Frachtführer ist verpflichtet, das zu beladende Fahrzeug
                  pünktlich und an dem Ort bereitzustellen, der sich aus dem
                  Transportauftrag ergibt. Die Bereitstellung eines Fahrzeugs
                  mit Parametern, die nicht mit dem Auftrag und/oder den ATB
                  übereinstimmen, gilt als mangelnde Bereitstellung.
                </li>
                <li>
                  Der Frachtführer ist verpflichtet, die Fahrer mit ordentlicher
                  und angemessener Arbeitskleidung auszustatten. Auf dem
                  Betriebsgelände des Auftragnehmers ist/sind der/die
                  Frachtführer verpflichtet, Warnwesten zu tragen.
                </li>
                <li>
                  Das Rauchen ist auf dem Betriebsgelände des Auftraggebers
                  verboten. Der Frachtführer /sein Fahrer ist verpflichtet,
                  dieses Verbot zu befolgen.
                </li>
                <li>
                  In der Zeit, in der der Frachtführer / sein Fahrer auf dem
                  Betriebsgelände des Auftraggebers auf die Beladung wartet, ist
                  es dem Frachtführer / seinem Fahrer untersagt: a) Gegenstände,
                  einschließlich Müll, zu hinterlassen, b) das Fahrzeug auf dem
                  Gelände des Auftraggebers zu waschen.
                </li>
                <li>
                  Der Frachtführer/sein Fahrer darf keine anderen Gegenstände
                  und Güter auf dem Sattelanhänger befördern als die, die im
                  Transportauftrag aufgeführt sind.
                </li>
                <li>
                  Der Frachtführer gewährleistet eine ständige Kommunikation mit
                  dem Auftraggeber und dem Fahrer, der den bestimmten
                  Frachtvertrag erfüllt.
                </li>
                <li>
                  Der Frachtführer/Fahrer haftet voll für die korrekte Beladung
                  und Verteilung der Güter im Laderaum, einschließlich des
                  korrekten Drucks auf die Fahrzeugachsen.
                </li>
                <li>
                  Es ist verboten, das beladene Fahrzeug auf dem Gelände des
                  Auftraggebers zu verlassen.
                </li>
                <li>
                  Der Frachtführer/sein Fahrer ist verpflichtet, die
                  Übereinstimmung der beladenen Güter mit den Dokumenten während
                  der Beladung zu überprüfen.
                </li>
                <li>
                  Bei fehlender Ladung oder anderen Problemen am Verladeort darf
                  der Frachtführer/sein Fahrer den Verladeort nicht ohne die
                  Zustimmung des Auftraggebers verlassen.
                </li>
                <li>
                  Bei Problemen am Entladeort darf der Frachtführer/sein Fahrer
                  den Entladeort nicht ohne die Zustimmung des Auftraggebers
                  verlassen.
                </li>
                <li>
                  Der Frachtführer darf andere Subunternehmer mit der Ausführung
                  des Transportauftrags nicht beauftragen oder ohne die
                  Zustimmung des Auftraggebers auf ein anderes Fahrzeug be- oder
                  umladen.
                </li>
                <li>
                  Der Frachtführer ist verpflichtet, vom Empfänger eine
                  Warenempfangsbestätigung auf dem in dem bestimmten Fall
                  verwendeten Dokument zu erhalten. Diese ist durch eine
                  leserliche Unterschrift der bevollmächtigten Person, einen
                  Firmen- und Personenstempel zu bestätigen. Auf Wunsch des
                  Auftraggebers ist der Frachtführer verpflichtet, das im
                  vorigen Satz erwähnte Dokument innerhalb von 48 Stunden nach
                  der Entladung vorzulegen, eine elektronische Version, die an
                  die E-Mail-Adresse des Auftraggebers geschickt wird, ist
                  erlaubt.
                </li>
                <li>
                  Der Frachtführer ist verpflichtet, die Ware genau an dem auf
                  dem Transportauftrag angegebenen Datum zu liefern. Der
                  Frachtführer nimmt die Tatsache zur Kenntnis, dass die
                  Transportaufträge für die Empfänger unter Anwendung strenger
                  Regeln bezüglich der Pünktlichkeit der Warenlieferung
                  realisiert werden, einschließlich der Bereitstellung von
                  Fahrzeugen, die zu einer bestimmten Uhrzeit entladen werden
                  sollen.
                </li>
                <li>
                  Der Frachtführer haftet für alle Schäden, die im Zusammenhang
                  mit der Erfüllung des Transportauftrags entstehen,
                  insbesondere für Warenschäden vom Zeitpunkt der
                  Transportannahme bis zur Ablieferung sowie für Schäden, die im
                  Zusammenhang mit falsch ausgefüllten oder verlorenen
                  Dokumenten, Verzögerungen bei der Ablieferung von Gütern;
                  vollständigem oder teilweisem Verlust, Beschädigung oder
                  Verlust von Gütern entstehen.
                </li>
                <li>
                  Nichteinhaltung irgendeiner der Bedingungen des
                  Transportauftrages und/oder der ATB, insbesondere die
                  verspätete/mangelnde Bereitstellung des Fahrzeuges zur
                  Beladung und die verspätete Lieferung von Gütern hat zur
                  Folge, dass der Auftraggeber eine Vertragsstrafe von bis zu
                  200% des Frachtpreises berechnet. Die im vorstehenden Satz
                  genannte Strafe ist innerhalb von 14 Tagen ab dem Datum der
                  durch den Auftraggeber ausgestellten Belastungsanzeige zu
                  zahlen. Der Auftraggeber behält sich das Recht vor, einen über
                  die Höhe der Vertragsstrafe hinausgehenden Schadenersatz zu
                  fordern.
                </li>
              </ul>
              <p>4. Abrechnung von Dienstleistungen</p>
              <ul>
                <li>
                  Der Frachtführer fügt der Rechnung Frachtdokumente bei,
                  darunter Dokumente zur Bestätigung der Lieferung, Lieferschein
                  (delivery note) und den Transportauftrag. Die Dokumente sind
                  innerhalb von 7 Tagen ab dem Datum der Entladung der Waren
                  vorzulegen. Die Zusendung der korrekt ausgefüllten Dokumente
                  ist eine Bedingung für die Bezahlung für die Dienstleistung.
                  Der Auftraggeber verpflichtet sich, innerhalb von 45 Tagen
                  nach Erhalt der korrekt ausgefüllten Dokumente zu zahlen.
                </li>
                <li>
                  Der Auftraggeber ist berechtigt, von der Vergütung des
                  Frachtführers alle Vertragsstrafen, die sich aus der ATB
                  ergeben, abzuziehen.
                </li>
                <li>
                  Der Frachtführer hat keinen Anspruch auf zusätzliches
                  Standgeld bei Be- und Entladung bis zu 24 Stunden im
                  Inlandsverkehr und bis zu 48 Stunden im Auslandsverkehr,
                  gerechnet ab Ende des vereinbarten Be- und Entladetages/der
                  vereinbarten Be- und Entladezeit, ausgenommen Feiertage. Die
                  Höhe des Standgeldes für längere Ausfallzeiten muss vereinbart
                  und vom Auftraggeber unter Androhung der Nichtigkeit
                  schriftlich bestätigt werden.
                </li>
              </ul>
              <p>5. Vom Auftraggeber verarbeitete personenbezogene Daten</p>
              <ul>
                <li>
                  Der Verantwortliche für die personenbezogenen Daten, die im
                  Zusammenhang mit Transportaufträgen verarbeitet werden, ist
                  der Auftraggeber - in dem unter Punkt 5.2 angegebenen Umfang.
                </li>
                <li>
                  Personenbezogene Daten: des Frachtführers, d.h. der
                  Vertragspartei (Grundlage der Verarbeitung -> Art. 6 Abs. 1
                  Buch. b) der DSGVO), sowie seiner Vertreter und anderer
                  Personen, die den Vertrag erfüllen, z.B. Angestellte oder
                  Mitarbeiter - einschließlich der zur Abholung und Lieferung
                  von Gütern berechtigten Fahrer - Grundlage der Verarbeitung ->
                  Art. 6 Abs. 1 Buch. f) DSGVO, d.h. das so genannte berechtigte
                  Interesse des Verantwortlichen), werden zum Zwecke des
                  Abschlusses und der Erfüllung des Vertrags durch den
                  Auftraggeber verarbeitet. Personenbezogene Daten können auch
                  zum Zweck der Geltendmachung, Feststellung oder Verteidigung
                  gegen die Ansprüche im Zusammenhang mit der Erfüllung des
                  gegenständlichen Vertrags verarbeitet werden (Grundlage der
                  Verarbeitung -> Art. 6 Abs. 1 Buch. f DSGVO, d.h. das so
                  genannte berechtigte Interesse des Verantwortlichen).
                  Personenbezogene Daten können auch zum Zweck der Erfüllung
                  öffentlich-rechtlicher Verpflichtungen verwendet werden, die
                  sich aus dem Gesetz ergeben (Art. 6 Abs. 1 Buch. c) der
                  DSGVO).
                </li>
                <li>
                  Die Daten werden keinen Dritten zugänglich gemacht, es sei
                  denn, sie sind für die Erfüllung des Vertrags, bei dem der
                  Auftraggeber Vertragspartei ist, erforderlich (z.B. Angabe der
                  Daten von Fahrern, die Waren empfangen oder an den Empfänger
                  liefern), oder ergeben sich aus gesetzlichen Bestimmungen.
                </li>
                <li>
                  Der Auftraggeber kann die Daten z.B. Anbietern von IT- oder
                  Buchhaltungsdienstleistungen anvertrauen oder die
                  Korrespondenz zwischen autorisierten Mitarbeitern des
                  Unternehmens weiterleiten.
                </li>
                <li>
                  Sie haben das Recht auf Zugang zu den Daten, einschließlich
                  des Erhalts einer Kopie der Daten, das Recht auf
                  Datenübertragbarkeit, das Recht auf Berichtigung und Löschung
                  der Daten, das Recht auf Einschränkung der Verarbeitung und
                  das Recht auf Widerspruch (wenn die Verarbeitung auf Art. 6
                  Abs. 1 Buch. f der DSGVO beruht). Sie haben das Recht, eine
                  Beschwerde bei der Aufsichtsbehörde (dem Präsidenten des Amtes
                  für den Schutz personenbezogener Daten) einzureichen.
                </li>
                <li>
                  Personenbezogene Daten werden spätestens nach Ablauf der
                  Verjährungsfrist möglicher Ansprüche im Zusammenhang mit der
                  Vertragserfüllung gelöscht oder anonymisiert, insbesondere
                  solche, die sich aus dem Prozess des Abschlusses und der
                  Erfüllung des vom Auftraggeber geschlossenen Vertrags,
                  öffentlich-rechtlichen Verpflichtungen ergeben, oder
                  einfacher, wenn Sie wirksam Widerspruch einlegen.
                </li>
                <li>
                  Die Bereitstellung von Daten ist freiwillig, aber für die oben
                  genannten Zwecke erforderlich.
                </li>
                <li>
                  In allen Fragen, die den Datenschutz betreffen, wenden Sie
                  sich bitte schriftlich an die Adresse des Auftraggebers oder
                  an folgende E-Mail-Adresse: rodo@palettenwerk.pl. Weitere
                  Informationen über die Verarbeitung Ihrer Daten finden Sie in
                  der Datenschutzerklärung auf der Website pallettenwerk.pl im
                  Reiter Kontakt.
                </li>
                <li>
                  In Anbetracht dessen, dass der Auftraggeber die
                  personenbezogenen Daten sowohl direkt - im Falle der Daten des
                  Frachtführers - als auch indirekt - im Falle der Daten seiner
                  Angestellten oder Mitarbeiter (die zu den oben genannten
                  Zwecken verarbeitet werden) erhalten kann, verpflichtet sich
                  der Frachtführer, allen Personen, deren Daten im Rahmen von
                  Transportaufträgen an den Auftraggeber weitergegeben werden,
                  unverzüglich eine Kopie dieser Informationsklausel (Abs. 1 - 8
                  oben) bereitzustellen und verpflichtet sich auch, diese
                  Personen über den Umfang der bereitgestellten
                  personenbezogenen Daten zu informieren.
                </li>
              </ul>
              <p>6. Schlussbestimmungen</p>
              <ul>
                <li>
                  Alle Informationen, die im Transportauftrag enthalten sind,
                  sowie Informationen, die der Frachtführer/sein Fahrer während
                  der Ausführung des Auftrags erhält, sind vertraulich und
                  stellen ein Betriebsgeheimnis des Auftraggebers dar. Diese
                  Informationen können nicht ohne die Zustimmung des
                  Auftraggebers verwendet und verbreitet werden. Bei Verletzung
                  des oben beschriebenen Betriebs- und Geschäftsgeheimnisses
                  durch den Frachtführer/seinen Fahrer zahlt der Frachtführer
                  dem Auftraggeber eine Vertragsstrafe in Höhe von 60 000 PLN.
                  Die im vorstehenden Satz genannte Strafe ist innerhalb von 14
                  Tagen ab dem Datum der durch den Auftraggeber ausgestellten
                  Belastungsanzeige zu zahlen. Übersteigt der Schaden die Höhe
                  der Vertragsstrafe, ist der Auftraggeber berechtigt,
                  Schadenersatz zu allgemeinen Bedingungen zu verlangen.
                </li>
                <li>
                  In Angelegenheiten, die nicht durch die ATB und den
                  Transportauftrag geregelt sind, gelten die Bestimmungen des
                  Zivilgesetzbuches und des Transportgesetzes und/oder der
                  CMR-Konvention
                </li>
              </ul>
              <p>7. Streitigkeiten</p>
              <p>
                Streitigkeiten werden in Übereinstimmung mit dem polnischen
                Recht und von dem für den Sitz des Auftraggebers zuständigen
                Gericht entschieden.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Owp;
